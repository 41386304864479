import { I as INTAKE_STATUS_COLORS, a as INTAKE_STATUS_TEXTS } from "./intake.status.js";
import { T as TRUCK_TYPE_INTAKE } from "./truck.types.js";
import { G as GondolaLogAndGrower, I as IntakeCode, a as IntakeInformation, N as NetProduceWeight, W as WeightInBinsComment, b as WeightOut } from "./index54.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "lodash";
import "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "create-edit-intake"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.menuItems
    }
  }), _c("a-row", {
    staticClass: "header pt-1 px-5 pb-3",
    attrs: {
      "align": "middle",
      "gutter": 16,
      "type": "flex"
    }
  }, [_c("a-col", [_c("a-icon", {
    staticClass: "h5",
    attrs: {
      "type": "arrow-left"
    },
    on: {
      "click": _vm.onBackClick
    }
  })], 1), _c("a-col", [_c("div", {
    staticClass: "h5"
  }, [_vm._v("Intake - " + _vm._s(_vm.intake.intakeNumber))])]), _c("a-col", [_c("tag-field", {
    attrs: {
      "color": _vm.statusColor,
      "value": _vm.statusText
    }
  })], 1)], 1), _c("a-row", {
    staticClass: "p-5"
  }, [_c("simple-form", [_c("a-row", {
    attrs: {
      "gutter": 24,
      "type": "flex"
    }
  }, [_c("a-col", {
    staticClass: "mb-4",
    attrs: {
      "span": 8
    }
  }, [_c("intake-code", {
    attrs: {
      "disabled": ""
    }
  })], 1), _c("a-col", {
    staticClass: "mb-4",
    attrs: {
      "span": 16
    }
  }, [_c("intake-information", {
    attrs: {
      "disabled": ""
    }
  })], 1), _c("a-col", {
    staticClass: "mb-4",
    attrs: {
      "span": 24
    }
  }, [_c("gondola-log-and-grower", {
    attrs: {
      "crop": _vm.selectedCrop,
      "disabled": "",
      "truck-id": _vm.selectedTruck.id
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 16
    }
  }, [_c("weight-in-bins-comment", {
    attrs: {
      "crop": _vm.selectedCrop,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 8
    }
  }, [_c("weight-out", {
    staticClass: "mb-4",
    attrs: {
      "disabled": "",
      "truck": _vm.selectedTruck
    }
  }), _c("net-produce-weight", {
    attrs: {
      "crop": _vm.selectedCrop,
      "disabled": ""
    }
  })], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns$1 = [];
var DetailUploadIntake_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "DetailUploadIntake",
  inject: ["resourceProps"],
  components: {
    GondolaLogAndGrower,
    IntakeCode,
    IntakeInformation,
    NetProduceWeight,
    WeightInBinsComment,
    WeightOut
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    const [intakeProps, , truckProps, intakeGrowerProps] = this.resourceProps;
    return {
      cancelVisible: false,
      intakeGrowerProps,
      intakeProps,
      isLoading: {
        SampleIdentifier: false,
        IntakeDocket: false
      },
      isSubmitting: false,
      menuItems: [],
      rejectVisible: false,
      selectedCrop: {},
      selectedTruck: {},
      truckProps
    };
  },
  computed: {
    crops() {
      return this.$store.getters["grower.common.crops/list"];
    },
    intake() {
      return this.intakeProps.crud.getEntity() || {};
    },
    statusColor() {
      return INTAKE_STATUS_COLORS[this.intake.status];
    },
    statusText() {
      return INTAKE_STATUS_TEXTS[this.intake.status];
    },
    trucks() {
      return this.truckProps.crud.getList();
    }
  },
  watch: {
    intake(newVal) {
      const { cropId, truckId } = newVal;
      this.selectedCrop = this.crops.find((crop) => crop.id == cropId) || {};
      this.selectedTruck = this.trucks.find((truck) => truck.id == truckId) || {};
    }
  },
  async created() {
    const { apiUrl } = this.truckProps;
    await this.truckProps.crud.fetchList(`${apiUrl}/grower/common/trucks?truckTypeId=${TRUCK_TYPE_INTAKE}`, void 0, false);
    await this.intakeProps.crud.fetchEntity();
    this.generateBreadcrumbItems();
  },
  methods: {
    cancel() {
      this.$router.push(this.intakeProps.redirectRoute);
    },
    generateBreadcrumbItems() {
      const { id } = this.intake;
      this.menuItems = [
        {
          key: "intakeprocessing",
          title: "Intake Processing",
          path: ""
        },
        {
          key: "intakelist",
          title: "Upload Intakes",
          path: "/watties-grower/intakes"
        },
        {
          key: "editintake",
          title: `Intake - ${this.intake.intakeNumber}`,
          path: `/watties-grower/upload-intakes/${id}`
        }
      ];
    },
    onBackClick() {
      this.$router.push(this.intakeProps.redirectRoute);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "6ea3588e", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var DetailUploadIntake = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", {
    attrs: {
      "name": "grower.common.crops",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.common.ware-houses",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.common.gondolas",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.common.paddock",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": ["grower.intakes", "grower.intakes.status", "grower.common.trucks", "grower.intakes.growers", "grower.common.growers", "grower.common.contracts-by-grower-pricingcrop", "grower.common.paddock-contract", "grower.common.grades", "grower.intakes.gondola-logs", "grower.intakes.active-gondola-logs", "grower.common.paddock-crop", "grower.common.tubs-truck", "grower.intakes.gross-weight", "grower.intakes.intake-bins", "grower.common.container-crop", "grower.intakes.weight-out", "grower.intakes.bunkers"],
      "api-url": _vm.apiUrl,
      "redirect-route": ["/watties-grower/upload-intakes"]
    }
  }, [_c("detail-upload-intake", {
    attrs: {
      "id": _vm.id
    }
  })], 1)], 1);
};
var staticRenderFns = [];
const __vue2_script = {
  components: {
    DetailUploadIntake
  },
  props: {
    id: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      apiUrl: "#{VUE_APP_API_URL}#"
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
